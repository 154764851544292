import { useCommonApi } from '~/api/common'
import { IContact } from '~/types/common'
export const useContact = () => {
  const { $axios } = useNuxtApp()
  const { BRANCH_CONTACT } = useCommonApi()
  const contacts = ref<IContact[]>([])
  const contactsMB = ref<IContact[]>([])

  const fetchBranchContact = async () => {
    try {
      const { data: response } = await $axios.get(BRANCH_CONTACT)
      if (response && response.status === 'OK') {
        contacts.value = response.data.slice(0, 3)
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }
  const fetchBranchMBContact = async () => {
    try {
      const { data: response } = await $axios.get(BRANCH_CONTACT)
      if (response && response.status === 'OK') {
        contactsMB.value = response.data.slice(0, 3)
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }

  return {
    fetchBranchContact,
    fetchBranchMBContact,
    contacts,
    contactsMB
  }
}
